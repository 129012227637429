import { NodeModel } from "@minoru/react-dnd-treeview";
import { InvoiceMethod, SubscriptionCycle, MoneyFromAPI } from "@simplicate/api-client";
import type { InvoicePrice } from "@simplicate/api-client/src/types";

export type ServiceData = {
  invoiceMethod: InvoiceMethod;
  subscriptionCycle?: SubscriptionCycle;
  saleQuantity?: number;
  hoursTotalAmount?: number;
  fixedPrice?: InvoicePrice;
  totalPrice?: MoneyFromAPI;
  hoursTotalBudget?: MoneyFromAPI;
  purchaseTotalBudget?: MoneyFromAPI;
  duplicateCallback?: () => void;
  isLoading?: boolean;
  loadingText?: string;
  deleteCallback?: () => void;
  editCallback?: () => void;
};

export enum ServiceGroupType {
  NORMAL = "normal",
  UNGROUPED = "ungrouped",
}

export type ServiceGroupData = {
  groupType?: ServiceGroupType;
  deleteCallback?: (shouldDeleteServices: boolean) => void;
  isLoading?: boolean;
  loadingText?: string;
  loadingMutation?: Record<string, boolean>;
  description?: string;
  editCallback?: () => void;
  total?: MoneyFromAPI;
};

export type ServiceNodeModel = NodeModel<ServiceData>;
export type ServiceGroupNodeModel = NodeModel<ServiceGroupData>;
export type GroupedServicesNodeModel = NodeModel<ServiceData | ServiceGroupData>;
