import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Page } from "@simplicate/ui";
import { NavCard } from "../../components";
import { IndexPageTemplate } from "./IndexPage.template";

export const IndexPage = () => {
  const { t } = useTranslation("insights");

  const { granted: hasAccessToHours } = usePermission("hours", "view");
  const { granted: hasHoursReportingPermission } = usePermission("hours", "hoursrapportage_all");

  return (
    <Page>
      <IndexPageTemplate>
        <IndexPageTemplate.Title title={t("index_page_title")} />
        <IndexPageTemplate.Body>
          {hasAccessToHours && hasHoursReportingPermission && (
            <>
              <IndexPageTemplate.BodySlot>
                <NavCard
                  dashboardId="dashboard/hours"
                  title={t("hours.report_title")}
                  module="hours"
                  description={t("hours.report_description")}
                />
              </IndexPageTemplate.BodySlot>
              <IndexPageTemplate.BodySlot>
                <NavCard
                  dashboardId="dashboard/hours-details"
                  title={t("hours.details_report_title")}
                  module="hours"
                  description={t("hours.details_report_description")}
                />
              </IndexPageTemplate.BodySlot>
            </>
          )}
        </IndexPageTemplate.Body>
      </IndexPageTemplate>
    </Page>
  );
};
