import { lightColorIconDisabled } from "@simplicate-software/design-tokens";
import { classNames } from "primereact/utils";
import { PropsWithChildren, useState, ReactElement, useId, useRef } from "react";
import { Button, ButtonProps } from "../Button";
import { Clickable, type ClickableProps } from "../Clickable";
import { Dropdown } from "../Dropdown";
import { Icon } from "../Icon";
import { List, ListItem } from "../List";
import { Popover } from "../Popover";
import styles from "./ActionDropdown.module.scss";
import { DropdownContext, useDropdownContext } from "./ActionDropdownContext";

const ActionDropdown = ({
  children,
  testId,
  ariaLabel,
  button,
}: PropsWithChildren<{ testId?: string; ariaLabel?: string; button?: ReactElement }>) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const dropdownAnchor = useRef<HTMLDivElement>(null);
  const dropdownButtonId = useId();

  const closeDropdown = () => setDropdownOpen(false);
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  return (
    <DropdownContext.Provider value={{ closeDropdown, toggleDropdown, dropdownButtonId }}>
      <div ref={dropdownAnchor}>
        {button ? (
          button
        ) : (
          <DropdownButton testId={testId && `${testId}-button`} variant="subtle" ariaLabel={ariaLabel}>
            <Icon icon="ellipsisVertical" />
          </DropdownButton>
        )}
      </div>
      <Popover anchor={dropdownAnchor} isVisible={dropdownOpen} align="end" position="bottom">
        <Dropdown
          testId={testId && `${testId}-dropdown`}
          width="auto"
          isOpen={dropdownOpen}
          onClickOutside={closeDropdown}
          onKeyupOutside={closeDropdown}
          onKeyboardDismiss={closeDropdown}
        >
          <List>{children}</List>
        </Dropdown>
      </Popover>
    </DropdownContext.Provider>
  );
};

type ActionProps = ClickableProps &
  PropsWithChildren<{
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    tooltip?: string;
  }>;

const Action = ({ className, onClick, children, disabled, tooltip, testId, ...clickableProps }: ActionProps) => {
  const { closeDropdown } = useDropdownContext();
  const handleClick = () => {
    onClick?.();

    closeDropdown?.();
  };

  return (
    <ListItem testId={testId && `${testId}-listitem`} disabled={disabled}>
      <Clickable
        testId={testId}
        onClick={handleClick}
        disabled={disabled}
        title={tooltip}
        className={classNames(className, styles.actionButton)}
        {...clickableProps}
      >
        <span data-testid={`${testId}-inner`} className={classNames(styles.inner, disabled && styles.disabled)}>
          {children}
        </span>
        {tooltip && <Icon className={styles.tooltip} icon="infoCircle" color={lightColorIconDisabled} />}
      </Clickable>
    </ListItem>
  );
};

ActionDropdown.Action = Action;

const DropdownButton = ({ onClick, ...props }: ButtonProps) => {
  const { toggleDropdown, dropdownButtonId } = useDropdownContext();

  const handleClick = () => {
    toggleDropdown?.();
    onClick?.();
  };

  return <Button type="button" {...props} onClick={handleClick} data-dropdown-button={dropdownButtonId} />;
};

ActionDropdown.Button = DropdownButton;

export { ActionDropdown };
