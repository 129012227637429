import classNames from "classnames";
import { Triangle } from "../Triangle";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[position]
import styles from "./Tooltip.module.scss";

type TooltipProps = {
  children: React.ReactNode;
  message: string;
  position?: "down" | "left" | "right" | "up";
};

export const Tooltip = ({ children, message, position = "up" }: TooltipProps) => {
  const positionMap: Record<typeof position, typeof position> = {
    up: "down",
    down: "up",
    left: "right",
    right: "left",
  };

  const trianglePosition = positionMap[position];

  return (
    <div className={styles.tooltip}>
      {children}
      <div className={classNames(styles.tooltipContent, styles[position])}>
        <div className={styles.triangleContainer}>
          <Triangle direction={trianglePosition} />
        </div>
        <span>{message}</span>
      </div>
    </div>
  );
};
