import { Navigate } from "react-router-dom";
import {
  ContentBlockEditor,
  EmailEditor,
  IFrameEnvironment,
  LegacyWebComponent,
  TemplateEditor,
} from "../../components";
import { QuoteTemplateTabs } from "../../components/IFrameEnvironment/Tabs";
import { ContentBlockEditorTabs } from "../../components/IFrameEnvironment/Tabs/Settings";
import { ContentBlockEditorHeader } from "../../components/NavigationWrapper/SubHeaders";
import { RouteObject } from "../types";
import { setWindowLocation } from "../utils";

const RedirectToClockAssist = () => {
  setWindowLocation("https://www.clockassist.com/nl/tijdregistratie-voor/simplicate");

  return null;
};

const STANDARD_INTEGRATIONS = [
  "dummy",
  "hubspot",
  "jiraChromeExtension",
  "jiraIntegration",
  "mailchimp",
  "peppol",
  "twinfieldpurchase",
] as const;

const INTEGRATIONS_WITH_MAPPING_PAGE = ["fiscaalgemak", "nextens", "hyarchis", "pinkweb"] as const;

const ACCOUNTANCY_INTEGRATIONS = ["fiscaalgemak", "nextens", "hyarchis", "pinkweb"] as const;

export const environmentManagementRoutes: RouteObject[] = [
  {
    path: "imports",
    element: <Navigate to="/settings/imports" replace />,
  },
  {
    path: "integrations",
    element: <Navigate to="/settings/integrations" replace />,
  },
  {
    path: "settings",
    handle: { headerTitle: "module_settings", theme: "environment-management" },
    children: [
      {
        index: true,
        element: <IFrameEnvironment src="/v1/environmentmanagement" />,
      },

      // Account
      {
        path: "subscription",
        handle: { backNavigationPath: "/settings", headerTitle: "subscription_settings" },
        children: [
          {
            index: true,
            element: <Navigate to="company_data" replace />,
          },
          {
            path: "company_data",
            element: <IFrameEnvironment src="/v1/environmentmanagement/subscription#company_data" />,
          },
          {
            path: "time_line",
            element: <IFrameEnvironment src="/v1/environmentmanagement/subscription#time_line" />,
          },
        ],
      },
      {
        path: "accountsettings",
        handle: { backNavigationPath: "/settings", headerTitle: "account_settings" },
        children: [
          {
            index: true,
            element: <Navigate to="general" replace />,
          },
          {
            path: "general",
            element: <IFrameEnvironment src="/v1/environmentmanagement/accountsettings#general" />,
          },
          {
            path: "password",
            element: <IFrameEnvironment src="/v1/environmentmanagement/accountsettings#password" />,
          },
          {
            path: "gdpr",
            element: <IFrameEnvironment src="/v1/environmentmanagement/accountsettings#gdpr" />,
          },
          {
            path: "admin_access",
            element: <IFrameEnvironment src="/v1/environmentmanagement/accountsettings#admin_access" />,
          },
          {
            path: "deletion_admin",
            element: <IFrameEnvironment src="/v1/environmentmanagement/accountsettings#deletion_admin" />,
          },
        ],
      },
      {
        path: "administrationsettings",
        handle: { backNavigationPath: "/settings", headerTitle: "administration_settings" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/administrationsettings" />,
          },
          {
            path: "view",
            element: <IFrameEnvironment src="/v1/environmentmanagement/administrationsettings/view" />,
          },
        ],
      },
      {
        path: "usersandgroups",
        handle: { backNavigationPath: "/settings", headerTitle: "users_and_groups_settings" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/usersandgroups" />,
          },
          {
            path: "view",
            element: <IFrameEnvironment src="/v1/environmentmanagement/usersandgroups/view" />,
          },
        ],
      },
      {
        path: "smartbcc",
        element: <IFrameEnvironment src="/v1/environmentmanagement/smartbcc" />,
        handle: { backNavigationPath: "/settings", headerTitle: "bcc_settings" },
      },
      {
        path: "mailmanager",
        handle: { backNavigationPath: "/settings", headerTitle: "mail_manager" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/mailmanager#mailmanager" />,
          },
          {
            path: "sendingdomains",
            element: <IFrameEnvironment src="/v1/environmentmanagement/mailmanager#sendingdomains" />,
          },
          {
            path: "mailmanagerpostmark",
            element: <IFrameEnvironment src="/v1/environmentmanagement/mailmanager#mailmanagerpostmark" />,
          },
          {
            path: "sendingdomainspostmark",
            element: <IFrameEnvironment src="/v1/environmentmanagement/mailmanager#sendingdomainspostmark" />,
          },
        ],
      },
      {
        path: "notifications",
        element: <LegacyWebComponent />,
        handle: { backNavigationPath: "/settings", headerTitle: "notifications_settings" },
      },
      {
        path: "integrations",
        handle: { backNavigationPath: "/settings", headerTitle: "integrations_settings" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/integrations" />,
          },
          {
            path: "mollie",
            handle: { backNavigationPath: "/settings/integrations" },
            children: [
              {
                index: true,
                element: <IFrameEnvironment src="/v1/integrations/mollie" />,
              },
              {
                path: "verifyapikey",
                element: (
                  <IFrameEnvironment
                    src="/v1/integrations/mollie/verifyapikey"
                    expectedRedirect="/settings/integrations/mollie"
                  />
                ),
              },
              {
                path: "revoke",
                element: (
                  <IFrameEnvironment
                    src="/v1/integrations/mollie/revoke"
                    expectedRedirect="/settings/integrations/mollie"
                  />
                ),
              },
            ],
          },
          {
            path: "exactpurchase",
            handle: { backNavigationPath: "/settings/integrations" },
            children: [
              {
                index: true,
                element: <IFrameEnvironment src="/v1/integrations/exactpurchase" />,
              },
              {
                path: "auth",
                element: (
                  <IFrameEnvironment
                    src="/v1/integrations/exactpurchase/auth"
                    expectedRedirect="/settings/integrations/exactpurchase"
                  />
                ),
              },
              {
                path: "callback",
                element: <IFrameEnvironment src="/v1/integrations/exactpurchase/callback" />,
              },
            ],
          },
          {
            path: "slack",
            handle: { backNavigationPath: "/settings/integrations" },
            children: [
              {
                index: true,
                element: <IFrameEnvironment src="/v1/integrations/slack" />,
              },
              {
                path: "auth",
                element: (
                  <IFrameEnvironment
                    src="/v1/integrations/slack/auth"
                    expectedRedirect="/settings/integrations/slack"
                  />
                ),
              },
              {
                path: "callback",
                element: (
                  <IFrameEnvironment
                    src="/v1/integrations/slack/callback"
                    expectedRedirect="/settings/integrations/slack"
                  />
                ),
              },
              {
                path: "refreshreceivers",
                element: (
                  <IFrameEnvironment
                    src="/v1/integrations/slack/refreshreceivers"
                    expectedRedirect="/settings/integrations/slack"
                  />
                ),
              },
              {
                path: "revoke",
                element: (
                  <IFrameEnvironment
                    src="/v1/integrations/slack/revoke"
                    expectedRedirect="/settings/integrations/slack"
                  />
                ),
              },
            ],
          },
          {
            path: "clockAssist",
            handle: { backNavigationPath: "/settings/integrations" },
            children: [
              {
                index: true,
                element: <IFrameEnvironment src="/v1/integrations/clockAssist" />,
              },
              {
                path: "redirectToClockAssist",
                element: <RedirectToClockAssist />,
              },
            ],
          },
          {
            path: "mailchimp",
            handle: { backNavigationPath: "/settings/integrations" },
            children: [
              {
                index: true,
                element: <IFrameEnvironment src="/v1/integrations/mailchimp" />,
              },
              {
                path: "reinstallWebhooks",
                element: (
                  <IFrameEnvironment
                    src="/v1/integrations/externallistwebhook/reinstallWebhooks"
                    expectedRedirect="/settings/integrations/mailchimp/webhooks"
                  />
                ),
              },
              {
                path: "webhooks",
                element: <IFrameEnvironment src="/v1/integrations/api/webhooks/mailchimp" />,
              },
            ],
          },

          ...STANDARD_INTEGRATIONS.map((integration) => ({
            path: integration,
            handle: { backNavigationPath: "/settings/integrations" },
            element: <IFrameEnvironment src={`/v1/integrations/${integration}`} />,
          })),

          ...ACCOUNTANCY_INTEGRATIONS.map((integration) => ({
            path: integration,
            handle: { backNavigationPath: "/settings/integrations" },
            children: [
              {
                index: true,
                element: <IFrameEnvironment src={`/v1/integrations/${integration}`} />,
              },
              {
                path: integration,
                element: (
                  <IFrameEnvironment
                    src={`/v1/integrations/${integration}/${integration}`}
                    expectedRedirect={`/settings/integrations/${integration}`}
                  />
                ),
              },
              {
                path: "revoke",
                element: (
                  <IFrameEnvironment
                    src={`/v1/integrations/${integration}/revoke`}
                    expectedRedirect={`/settings/integrations/${integration}`}
                  />
                ),
              },
            ],
          })),

          ...INTEGRATIONS_WITH_MAPPING_PAGE.map((integration) => ({
            path: integration,
            handle: { backNavigationPath: "/settings/integrations" },
            children: [
              {
                index: true,
                element: <IFrameEnvironment src={`/v1/integrations/${integration}`} />,
              },
              {
                path: "mapping",
                element: <IFrameEnvironment src={`/v1/integrations/${integration}/mapping`} />,
              },
            ],
          })),
        ],
      },
      {
        path: "api",
        element: <IFrameEnvironment src="/v1/environmentmanagement/api" />,
        handle: { backNavigationPath: "/settings", headerTitle: "api_settings" },
      },

      // Account -- admin users
      {
        path: "general",
        handle: { backNavigationPath: "/settings" },
        children: [
          {
            path: "restoreHours",
            element: <IFrameEnvironment src="/v1/environmentmanagement/general/restoreHours" />,
          },
          {
            path: "importOverview",
            element: <IFrameEnvironment src="/v1/environmentmanagement/general/importOverview" />,
          },
          {
            path: "skippedInvoiceNumbersGrid",
            element: <IFrameEnvironment src="/v1/environmentmanagement/general/skippedInvoiceNumbersGrid" />,
          },
        ],
      },
      {
        path: "default",
        handle: { backNavigationPath: "/settings" },
        children: [
          {
            path: "settingsLogging",
            element: <IFrameEnvironment src="/v1/environmentmanagement/default/settingsLogging" />,
          },
          {
            path: "relationActionGrid",
            element: <IFrameEnvironment src="/v1/environmentmanagement/default/relationActionGrid" />,
          },
        ],
      },

      // General
      {
        path: "customfields",
        element: <IFrameEnvironment src="/v1/environmentmanagement/customfields" />,
        handle: { backNavigationPath: "/settings", headerTitle: "custom_fields_settings" },
      },
      {
        path: "dossier",
        handle: { backNavigationPath: "/settings", headerTitle: "dossier_settings" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/dossier" />,
          },
          {
            path: "view",
            element: <IFrameEnvironment src="/v1/environmentmanagement/dossier/view" />,
          },
          {
            path: "workflowgroup",
            element: <IFrameEnvironment src="/v1/environmentmanagement/dossier/workflowgroup" />,
          },
        ],
      },
      {
        path: "document",
        handle: { backNavigationPath: "/settings", headerTitle: "document_settings" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/document" />,
          },
          {
            path: "view",
            element: <IFrameEnvironment src="/v1/environmentmanagement/document/view" />,
          },
        ],
      },
      {
        path: "import",
        handle: { backNavigationPath: "/settings", headerTitle: "import_settings" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/import" />,
          },
          {
            path: "projects",
            element: <IFrameEnvironment src="/v1/environmentmanagement/import/projects" />,
          },
        ],
      },
      {
        path: "imports",
        element: <IFrameEnvironment src="/v1/imports" />,
        handle: { backNavigationPath: "/settings", headerTitle: "import_settings" },
      },
      {
        path: "paperlayout",
        handle: { backNavigationPath: "/settings", headerTitle: "paperlayout_settings" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/paperlayout" />,
          },
          {
            path: "paperlayout",
            element: <IFrameEnvironment src="/v1/environmentmanagement/paperlayout/paperlayout" />,
          },
        ],
      },
      {
        path: "templates/view",
        element: <IFrameEnvironment src="/v1/environmentmanagement/templates/view" />,
      },

      // Feature module settings
      {
        path: "crm",
        handle: { backNavigationPath: "/settings", headerTitle: "crm" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/crm" />,
          },
          {
            path: "view",
            element: <IFrameEnvironment src="/v1/environmentmanagement/crm/view" />,
          },
          {
            path: "accountancycustomfields",
            handle: { backNavigationPath: "/settings/crm#accountancycustomfields", headerTitle: "crm" },
            element: <IFrameEnvironment src="/v1/environmentmanagement/crm/accountancycustomfields" />,
          },
        ],
      },
      {
        path: "hrm",
        handle: { backNavigationPath: "/settings", headerTitle: "hrm" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/hrm" />,
          },
          {
            path: "view",
            element: <IFrameEnvironment src="/v1/environmentmanagement/hrm/view" />,
          },
        ],
      },
      {
        path: "sales",
        handle: { backNavigationPath: "/settings", headerTitle: "sales" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/acquisition" />,
          },
          {
            path: "view",
            element: <IFrameEnvironment src="/v1/environmentmanagement/acquisition/view" />,
          },
          {
            path: "templates",
            children: [
              {
                index: true,
                element: <LegacyWebComponent />,
              },
              {
                path: ":id",
                handle: {
                  tabsComponent: QuoteTemplateTabs,
                  backNavigationPath: "/settings/sales/templates",
                },
                children: [
                  {
                    index: true,
                    element: <Navigate to="editor" replace />,
                  },
                  {
                    path: "editor",
                    element: <TemplateEditor />,
                  },
                  {
                    path: "email",
                    element: <EmailEditor />,
                  },
                  {
                    path: "quoteportal",
                    element: <LegacyWebComponent />,
                  },
                ],
              },
            ],
          },
          {
            path: "quotecontentblocks",
            children: [
              {
                index: true,
                element: <LegacyWebComponent />,
              },
              {
                path: ":id",
                children: [
                  {
                    index: true,
                    element: <Navigate to="editor" replace />,
                  },
                  {
                    path: "editor",
                    element: <ContentBlockEditor />,
                    handle: {
                      backNavigationPath: "/settings/sales/quotecontentblocks",
                      tabsComponent: ContentBlockEditorTabs,
                      subHeaderComponent: ContentBlockEditorHeader,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "projects",
        handle: { backNavigationPath: "/settings", headerTitle: "projects" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/projects" />,
          },
          {
            path: "view",
            element: <IFrameEnvironment src="/v1/environmentmanagement/projects/view" />,
          },
          {
            path: "priceIndexing",
            element: <IFrameEnvironment src="/v1/environmentmanagement/projects/priceIndexing" />,
          },
        ],
      },
      {
        path: "resourceplanner",
        handle: { backNavigationPath: "/settings" },
        children: [
          { index: true, element: <Navigate to="general" /> },
          {
            path: "general",
            element: <LegacyWebComponent />,
          },
          {
            path: "assignmentstatuses",
            element: <LegacyWebComponent />,
          },
        ],
      },
      {
        path: "hours",
        handle: { backNavigationPath: "/settings" },
        children: [
          { index: true, element: <Navigate to="register" /> },
          {
            path: "register",
            element: <LegacyWebComponent />,
          },
          {
            path: "review",
            element: <LegacyWebComponent />,
          },
        ],
      },
      {
        path: "invoices",
        handle: { backNavigationPath: "/settings", headerTitle: "invoices" },
        children: [
          {
            index: true,
            element: <IFrameEnvironment src="/v1/environmentmanagement/invoices" />,
          },
          {
            path: "view",
            element: <IFrameEnvironment src="/v1/environmentmanagement/invoices/view" />,
          },
          {
            path: "invoicetemplate",
            element: <IFrameEnvironment src="/v1/environmentmanagement/invoices/invoicetemplate" />,
          },
          {
            path: "remindertemplate",
            element: <IFrameEnvironment src="/v1/environmentmanagement/invoices/remindertemplate" />,
          },
          {
            path: "reminderset",
            element: <IFrameEnvironment src="/v1/environmentmanagement/invoices/reminderset" />,
          },
          {
            path: "remindersettemplate",
            element: <IFrameEnvironment src="/v1/environmentmanagement/invoices/remindersettemplate" />,
          },
        ],
      },
      {
        path: "apps",
        handle: { backNavigationPath: "/settings" },
        children: [
          {
            index: true,
            element: <Navigate to="accountancyApps" replace />,
          },
          {
            path: "accountancyApps",
            handle: { headerTitle: "accounting_integrations" },
            children: [
              {
                index: true,
                element: <IFrameEnvironment src="/v1/environmentmanagement/apps/accountancyApps" />,
              },
              {
                path: "install",
                element: <IFrameEnvironment src="/environmentmanagement/apps/installExternalAccounting" />,
                handle: { backNavigationPath: "/settings/apps/accountancyApps" },
              },
            ],
          },
          {
            path: "install",
            element: <IFrameEnvironment src="/v1/environmentmanagement/apps/install" />,
          },
          {
            path: "configure",
            element: <IFrameEnvironment src="/v1/environmentmanagement/apps/configure" />,
          },
          {
            path: "twinfield",
            element: <IFrameEnvironment src="/apps/twinfield/install" />,
          },
        ],
      },
    ],
  },
];
