import classNames from "classnames";
import { CSSProperties, PropsWithChildren } from "react";
import styles from "./ColumnsLayout.module.scss";

const COLSPAN_HUGE_SCREENS = "--colspan-huge-screens" as keyof CSSProperties;
const COLSPAN_LARGE_SCREENS = "--colspan-large-screens" as keyof CSSProperties;
const COLSPAN_MEDIUM_SCREENS = "--colspan-medium-screens" as keyof CSSProperties;
const COLSPAN_SMALL_SCREENS = "--colspan-small-screens" as keyof CSSProperties;

type ColSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type ClassNameProps = { className?: string };
type ColumnsLayoutProps = PropsWithChildren<ClassNameProps>;
type ColumnsLayoutSlotProps = PropsWithChildren<
  ClassNameProps & {
    /**
     * The number of columns this slot should span if the parent container is at most 720px (45rem) wide.
     * Defaults to 12.
     */
    onSmallScreens?: ColSpan;

    /**
     * The number of columns this slot should span if the parent container is between 720px (45rem) and 1024px (64rem) wide.
     * Defaults to the value of `onSmallScreens`.
     */
    onMediumScreens?: ColSpan;

    /**
     * The number of columns this slot should span if the parent container is between 1024px (64rem) and 1440px (90rem) wide.
     * Defaults to the value of `onMediumScreens`.
     */
    onLargeScreens?: ColSpan;

    /**
     * The number of columns this slot should span if the parent container is at least 1440px (90rem) wide.
     * Defaults to the value of `onLargeScreens`.
     */
    onHugeScreens?: ColSpan;

    /**
     * Whether the slot enables subgrid on its columns, allowing for (multiple) nested layers of slots.
     */
    hasSubgridOnColumns?: boolean;

    /**
     * Whether the slot enables subgrid on its rows, allowing for alignment of content.
     */
    hasSubgridOnRows?: boolean;
  }
>;

export const ColumnsLayout = ({ children, className }: ColumnsLayoutProps) => (
  <div className={classNames(styles.columnsLayout, className)}>{children}</div>
);

ColumnsLayout.Slot = function ColumnsLayoutSlot({
  className,
  children,
  hasSubgridOnColumns = false,
  hasSubgridOnRows = false,
  onHugeScreens,
  onLargeScreens,
  onMediumScreens,
  onSmallScreens = 12,
}: ColumnsLayoutSlotProps) {
  return (
    <div
      className={classNames(
        styles.columnsLayoutSlot,
        hasSubgridOnColumns && styles.withColumnSubgrid,
        hasSubgridOnRows && styles.withRowSubgrid,
        className,
      )}
      style={{
        [COLSPAN_HUGE_SCREENS]: onHugeScreens,
        [COLSPAN_LARGE_SCREENS]: onLargeScreens,
        [COLSPAN_MEDIUM_SCREENS]: onMediumScreens,
        [COLSPAN_SMALL_SCREENS]: onSmallScreens,
      }}
    >
      {children}
    </div>
  );
};
