// istanbul ignore file -- This component will be covered by e2e tests
import { Query } from "@cubejs-client/core";
import { usePageTitle } from "@simplicate/state";
import { TFunction, useTranslation } from "@simplicate/translations";
import { Page } from "@simplicate/ui";
import { size10, size11, size6, size9 } from "@simplicate-software/design-tokens";
import { HoursReportPageTemplate } from "..//HoursReportPage/HoursReportPage.template";
import { CubeProvider, Dashboard } from "../../components";
import { FilterWidget, TableWidget, TableWidgetColumn } from "../../widgets";
import { Mapping, useSearchParamFilters } from "./useSearchParamFilters";

const QUERY: Query = {
  timeDimensions: [
    {
      dimension: "hours_details.date",
      dateRange: "this month",
    },
  ],
  dimensions: [
    "hours_details.employee_identifier",
    "hours_details.employee_name",
    "hours_details.date",
    "hours_details.time_entry_id",
    "hours_details.item_type",
    "hours_details.description",
    "hours_details.amount",
    "hours_details.tariff",
    "hours_details.calculated_value",
    "hours_details.employee_avatar",
    "hours_details.employee_initials",
  ],
  measures: ["hours_details.value_after_correction"],
  order: {
    "hours_details.date": "desc",
  },
};

const EMPLOYEE_NAME_COLUMN = ["hours_details", "employee_name"] as const;
const EMPLOYEE_IDENTIFIER_COLUMN = ["hours_details", "employee_identifier"] as const;
const TIME_ENTRY_DATE = ["hours_details", "date"] as const;
const TIME_ENTRY_ITEM_TYPE = ["hours_details", "item_type"] as const;
const TIME_ENTRY_DESCRIPTION = ["hours_details", "description"] as const;
const TIME_ENTRY_AMOUNT = ["hours_details", "amount"] as const;
const TIME_ENTRY_TARIFF = ["hours_details", "tariff"] as const;
const TIME_ENTRY_CALCULATED_VALUE = ["hours_details", "calculated_value"] as const;
const TIME_ENTRY_VALUE_AFTER_CORRECTION = ["hours_details", "value_after_correction"] as const;
const EMPLOYEE_AVATAR_DIMENSION = ["hours_details", "employee_avatar"] as const;
const EMPLOYEE_INITIALS_DIMENSION = ["hours_details", "employee_initials"] as const;

const DASHBOARD_ID = "hours-details-report";
const COLUMNS_CONFIG: TableWidgetColumn[] = [
  {
    dimension: EMPLOYEE_NAME_COLUMN,
    title: (t: TFunction<"insights">) => t("hours.employee_column_title"),
    sortable: true,
    width: "dynamic",
    align: "left",
    avatarCfg: {
      avatarDimension: EMPLOYEE_AVATAR_DIMENSION,
      initialsDimension: EMPLOYEE_INITIALS_DIMENSION,
    },
  },
  {
    dimension: EMPLOYEE_IDENTIFIER_COLUMN,
    title: () => "id",
    hidden: true,
  },
  {
    dimension: TIME_ENTRY_DATE,
    title: (t: TFunction<"insights">) => t("hours.time_entry_date"),
    sortable: true,
    width: size9,
    align: "left",
    format: "date",
  },
  {
    dimension: TIME_ENTRY_ITEM_TYPE,
    title: (t: TFunction<"insights">) => t("hours.time_entry_item_type"),
    sortable: true,
    width: size10,
    align: "left",
  },
  {
    dimension: TIME_ENTRY_DESCRIPTION,
    title: (t: TFunction<"insights">) => t("hours.time_entry_description"),
    sortable: true,
    width: size11,
    align: "left",
  },
  {
    dimension: TIME_ENTRY_AMOUNT,
    title: (t: TFunction<"insights">) => t("hours.time_entry_amount"),
    sortable: true,
    isNumeric: true,
    width: size6,
    format: "number",
    align: "right",
  },
  {
    dimension: TIME_ENTRY_TARIFF,
    title: (t: TFunction<"insights">) => t("hours.time_entry_tariff"),
    sortable: true,
    isNumeric: true,
    width: size9,
    format: "currency",
    align: "right",
  },
  {
    dimension: TIME_ENTRY_CALCULATED_VALUE,
    title: (t: TFunction<"insights">) => t("hours.time_entry_calculated_tariff"),
    sortable: true,
    isNumeric: true,
    width: size9,
    format: "currency",
    align: "right",
  },
  {
    dimension: TIME_ENTRY_VALUE_AFTER_CORRECTION,
    title: (t: TFunction<"insights">) => t("hours.time_entry_value_after_correction"),
    sortable: true,
    isNumeric: true,
    width: size9,
    format: "currency",
    align: "right",
  },
];

const SEARCH_PARAM_MAPPING: Mapping[] = [
  {
    label: {
      recordKey: EMPLOYEE_NAME_COLUMN,
      searchParam: "employeeName",
    },
    value: {
      recordKey: EMPLOYEE_IDENTIFIER_COLUMN,
      searchParam: "id",
    },
  },
];

export const HoursReportPageDetails = () => {
  const { t } = useTranslation("insights");
  const filters = useSearchParamFilters(SEARCH_PARAM_MAPPING);

  usePageTitle(t("hours.details_report_title"));

  return (
    <Page>
      <CubeProvider>
        <Dashboard query={QUERY} dashboardId={DASHBOARD_ID} filters={filters}>
          <HoursReportPageTemplate>
            <HoursReportPageTemplate.Filters>
              <FilterWidget dimensions={[]} />
            </HoursReportPageTemplate.Filters>
            <HoursReportPageTemplate.KpiContainer></HoursReportPageTemplate.KpiContainer>
            <HoursReportPageTemplate.Table>
              <TableWidget title={t("hours.time_entry_table_title")} columns={COLUMNS_CONFIG} />
            </HoursReportPageTemplate.Table>
          </HoursReportPageTemplate>
        </Dashboard>
      </CubeProvider>
    </Page>
  );
};
