import { BinaryFilter } from "@cubejs-client/core";
import { endOfWeek, startOfWeek } from "date-fns";
import { Filter, CubeDimension, cubeDimensionToKey } from "../../types";

type FilterValues = Record<string, string[]>;

export type DashboardState = {
  filters: Filter[];
  filterValues: FilterValues;
  search: string;
  order?: {
    key: string;
    direction: "asc" | "desc";
  };
  dateRange: {
    start: Date;
    end: Date;
  };
  cubeQuery?: {
    filters: BinaryFilter[];
    order?: Record<string, "asc" | "desc">;
  };
};

type ResetAction = {
  type: "RESET";
};

type OrderByAction = {
  type: "ORDER_BY";
  key: string;
};

type ApplyFilterForDimensionsAction = {
  type: "APPLY_FILTER_FOR_DIMENSION";
  config: { labelDimension: CubeDimension; valueDimension: CubeDimension };
  values: { label: string; value: string }[];
};

type RemoveFilterAction = {
  type: "REMOVE_FILTER";
  filter: Filter;
};

type SetSearchAction = {
  type: "SET_SEARCH";
  search: string;
};

type SetStartDateAction = {
  type: "SET_START_DATE";
  date: Date;
};

type SetEndDateAction = {
  type: "SET_END_DATE";
  date: Date;
};

export type ReducerAction =
  | ApplyFilterForDimensionsAction
  | OrderByAction
  | RemoveFilterAction
  | ResetAction
  | SetEndDateAction
  | SetSearchAction
  | SetStartDateAction;

function createDefaultState(): DashboardState {
  return {
    filters: [],
    filterValues: {},
    search: "",
    dateRange: {
      start: startOfWeek(new Date()),
      end: endOfWeek(new Date()),
    },
  };
}

function buildOrderingState(
  { order }: Pick<DashboardState, "order">,
  action: OrderByAction,
): Pick<DashboardState, "order"> {
  if (!order) {
    return { order: { key: action.key, direction: "asc" } };
  }

  const { key, direction } = order;

  return {
    order: {
      key: action.key,
      direction: key === action.key && direction === "asc" ? "desc" : "asc",
    },
  };
}

function dashboardReducer(state: DashboardState, action: ReducerAction): Omit<DashboardState, "cubeQuery"> {
  switch (action.type) {
    case "APPLY_FILTER_FOR_DIMENSION":
      return {
        ...state,
        filters: [
          ...state.filters.filter(
            (filter) => cubeDimensionToKey(filter.valueDimension) !== cubeDimensionToKey(action.config.valueDimension),
          ),
          ...action.values.map((value) => ({
            ...value,
            valueDimension: action.config.valueDimension,
            labelDimension: action.config.labelDimension,
          })),
        ],
      };

    case "RESET":
      return {
        ...state,
        ...createDefaultState(),
      };

    case "ORDER_BY":
      return {
        ...state,
        ...buildOrderingState(state, action),
      };

    case "REMOVE_FILTER":
      return {
        ...state,
        filters: state.filters.filter((filter) => filter !== action.filter),
      };

    case "SET_END_DATE":
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          end: action.date,
        },
      };

    case "SET_SEARCH":
      return {
        ...state,
        search: action.search,
      };

    case "SET_START_DATE":
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          start: action.date,
        },
      };
  }
}

function finaliseState(state: DashboardState): DashboardState {
  const filters = [...state.filters].sort(
    (a, b) =>
      cubeDimensionToKey(a.labelDimension).localeCompare(cubeDimensionToKey(b.labelDimension)) ||
      a.label.localeCompare(b.label),
  );

  const uniqueFilterDimensions = new Set(
    state.filters.map(({ valueDimension }) => cubeDimensionToKey(valueDimension)) ?? [],
  );

  return {
    ...createDefaultState(),
    ...state,
    filters,
    filterValues: Object.fromEntries(
      [...uniqueFilterDimensions].map(
        (dimension) =>
          [
            dimension,
            state.filters
              .filter(({ valueDimension }) => cubeDimensionToKey(valueDimension) === dimension)
              .map(({ value }) => value),
          ] as const,
      ),
    ),
    cubeQuery: {
      filters: state.filters.map(({ value, valueDimension }) => ({
        member: cubeDimensionToKey(valueDimension),
        operator: "equals",
        values: [value],
      })),
      order: state.order ? { [state.order.key]: state.order.direction } : undefined,
    },
  };
}

export function createInitialState(state: Partial<DashboardState>): DashboardState {
  const defaultState = createDefaultState();

  return finaliseState({
    ...defaultState,
    ...state,
    dateRange: state.dateRange ?? defaultState.dateRange,
  });
}

export function dashboardStateReducer(state: DashboardState, action: ReducerAction): DashboardState {
  const newState = dashboardReducer(state, action);

  return finaliseState(newState);
}
