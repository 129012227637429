import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Button, Table, Icon } from "@simplicate/ui";
import classNames from "classnames";
import { useState } from "react";
import { type CostTypeInForm } from "../../../types";
import { AddCostTypeRow } from "./AddCostTypeRow";
import styles from "./CostTypeGridFooter.module.scss";
import type { Big } from "@simplicate/api-client";

type CostTypeGridFooterProps = {
  onAddCostType: (costType: CostTypeInForm) => void;
  totalBudgetMonetaryValue: Big;
  totalMonetaryValue: Big;
  disabled: boolean;
};

export const CostTypeGridFooter = ({
  onAddCostType,
  totalBudgetMonetaryValue,
  totalMonetaryValue,
  disabled,
}: CostTypeGridFooterProps) => {
  const { t } = useTranslation("project_services");
  const [isAddingCostType, setIsAddingCostType] = useState(false);

  return (
    <>
      {isAddingCostType && (
        <Table.Footer variant="secondary">
          <AddCostTypeRow
            onConfirmNewEntry={
              /* istanbul ignore next - submitForm function is mocked in tests */
              (costType) => {
                onAddCostType(costType);
                setIsAddingCostType(false);
              }
            }
            onCancelNewEntry={() => setIsAddingCostType(false)}
            disabled={disabled}
          />
        </Table.Footer>
      )}
      <Table.Footer testId="cost-type-grid-footer" className={styles.footer}>
        <Button
          testId="add-new-cost-type"
          variant="secondary"
          onClick={() => setIsAddingCostType(true)}
          disabled={disabled || isAddingCostType}
          type="button"
        >
          <Icon icon="plus" />
          {t("add_new_cost_type_button")}
        </Button>
        <div className={styles.budget}>
          <span>{t("cost_type_budget_footer_label")}</span>
          <span className={classNames(styles.alignRight, styles.offsetRight, styles.ellipses, styles.fontNumeric)}>
            <CurrencyFormat
              testId="budget_total_value"
              value={totalBudgetMonetaryValue.toString()}
              decimalScale={2}
              displayType="text"
              size="small"
            />
          </span>
        </div>
        <span data-cost-type-total={true} className={classNames(styles.alignRight, styles.offsetRight)}>
          {t("cost_type_total_label")}
        </span>
        <span className={classNames(styles.alignRight, styles.ellipses, styles.fontNumeric)}>
          <CurrencyFormat
            testId="total_value"
            value={totalMonetaryValue.toString()}
            decimalScale={2}
            displayType="text"
            size="small"
          />
        </span>
      </Table.Footer>
    </>
  );
};
