import { TFunction, useTranslation } from "@simplicate/translations";
import { Table, buildColumnProps, SkeletonLoader, Widget, Counter, Avatar } from "@simplicate/ui";
import { useMemo, useCallback } from "react";
import { useDashboardContext } from "../../components/Dashboard";
import { FormattedValue } from "../../components/FormattedValue";
import { CubeDimension, cubeDimensionToKey, Formatter, RecordShape } from "../../types";
import styles from "./TableWidget.module.scss";

type AvatarConfig = {
  avatarDimension: CubeDimension;
  initialsDimension: CubeDimension;
};

type AvatarColumnProps = {
  column: TableWidgetColumn;
  value?: boolean | number | string;
  url: string;
  altText: string;
};

const AvatarColumn = ({ column, value, url, altText }: AvatarColumnProps) => (
  <span className={styles.wrapper}>
    <span className={styles.avatarWrapper}>
      <Avatar size="small" src={url} altText={altText} />
    </span>
    <FormattedValue formatter={column.format} value={value} />
  </span>
);

// Top UI (3rem) + height of filterwidget (100px) + height of the table header (40px) = 188px
// Negative values lower the observed boundary the element needs to cross to be out of view
const STICKY_HEADER_MARGIN = "-188px";

export type TableWidgetColumn = Parameters<typeof buildColumnProps>[0] & {
  dimension: CubeDimension;
  title: (t: TFunction<"insights">) => string;
  format?: Formatter;
  avatarCfg?: AvatarConfig;
};

type TableWidgetProps = {
  title: string;
  columns: readonly TableWidgetColumn[];
};

export const TableWidget = ({ title, columns }: TableWidgetProps) => {
  const { t } = useTranslation("insights");
  const {
    state: { order },
    actions: { orderBy },
    queryResult: { resultSet, isLoading },
    onRecordSelect,
  } = useDashboardContext();
  const dimensions = useMemo(
    () =>
      columns
        .flatMap((column) => [column.dimension, column.avatarCfg?.avatarDimension, column.avatarCfg?.initialsDimension])
        .filter((dimension: CubeDimension | undefined): dimension is CubeDimension => dimension !== undefined),
    [columns],
  );

  const formattedColumns = useMemo(() => {
    return columns.map((column: TableWidgetColumn) => {
      const fieldName = cubeDimensionToKey(column.dimension);

      return {
        ...column,
        header: column.title(t),
        body: (record: Record<string, boolean | number | string>) => {
          const value = record[fieldName];

          if (column.avatarCfg !== undefined) {
            const filename = record[cubeDimensionToKey(column.avatarCfg.avatarDimension)];
            const url = `/api/v2/storage/thumbnail?filename=${filename}&thumbnailsize=2x2cm&validation=avatar`;
            const altText = record[cubeDimensionToKey(column.avatarCfg.initialsDimension)];

            return <AvatarColumn column={column} value={value} altText={altText?.toString() ?? ""} url={url} />;
          }

          return <FormattedValue formatter={column.format} value={value} />;
        },
      };
    });
  }, [columns, t]);

  const [sortField, sortOrder] = useMemo(() => {
    if (!order) return [undefined, undefined];

    return [order.key, order.direction === "asc" ? 1 : -1] as const;
  }, [order]);

  const value = useMemo(() => {
    if (isLoading) return new Array(10).fill(null).map(() => ({}));
    if (!resultSet) return [];

    const processRow = (row: Record<string, unknown>) => {
      const result: Record<string, unknown> = {};

      dimensions.forEach((dimension) => {
        const key = cubeDimensionToKey(dimension);

        result[key] = row[key];
      });

      return result;
    };

    return resultSet.tablePivot().map(processRow);
  }, [dimensions, isLoading, resultSet]);

  const handleSort = useCallback(({ sortField }: { sortField: string }) => orderBy(sortField), [orderBy]);
  const handleRowClick = useCallback(
    (event: { data: RecordShape }) => {
      onRecordSelect?.(event.data);
    },
    [onRecordSelect],
  );

  return (
    <Widget
      title={title}
      variant="borderless"
      titleSize="large"
      annotation={!isLoading && <Counter value={value.length} max={false} />}
    >
      <Table
        value={value}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSort}
        onRowClick={handleRowClick}
        enableStickyHeader
        stickyHeaderMargin={STICKY_HEADER_MARGIN}
      >
        {formattedColumns.map((column) => (
          <Table.Column
            key={cubeDimensionToKey(column.dimension)}
            {...buildColumnProps({
              ...column,
              field: cubeDimensionToKey(column.dimension),
              body: isLoading ? <SkeletonLoader /> : column.body,
            })}
          />
        ))}
      </Table>
    </Widget>
  );
};
