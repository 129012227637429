import { DeeplyReadonly, Query } from "@cubejs-client/core";
import { PropsWithChildren, useCallback, useEffect, useReducer } from "react";
import {
  useAppSelector,
  DashboardViewState,
  getDashboardById,
  updateDashboard,
  useAppDispatch,
  useDataset,
} from "../../data";
import { useDateRangeSearchQuery } from "../../hooks";
import { Filter, RecordShape } from "../../types";
import { DashboardContext, DashboardContextType } from "./Dashboard.context";
import { createInitialState, dashboardStateReducer } from "./Dashboard.reducer";

export type RecordSelectHandler = (
  record: RecordShape,
  { context }: { context: Omit<DashboardContextType, "dispatch" | "onRecordSelect"> },
) => void;

type DashboardProps = PropsWithChildren<{
  dashboardId: string;
  query: DeeplyReadonly<Query>;
  filters?: Filter[];
  onRecordSelect?: RecordSelectHandler;
}>;

export const Dashboard = ({ children, query, dashboardId, filters, onRecordSelect }: DashboardProps) => {
  const dateRange = useDateRangeSearchQuery();
  const { order, filters: stateFilters } = useAppSelector(getDashboardById(dashboardId));
  const appDispatch = useAppDispatch();

  const [state, dispatch] = useReducer(dashboardStateReducer, null, () =>
    createInitialState({ order, filters: filters ?? stateFilters, dateRange }),
  );
  const queryResult = useDataset({ ...query, state });

  const handleRecordSelect = useCallback(
    (record: RecordShape) => {
      onRecordSelect?.(record, { context: { state, queryResult } });
    },
    [onRecordSelect, queryResult, state],
  );

  useEffect(() => {
    const dashboardState = {
      dashboardId,
      order: state.order,
      filters: state.filters,
    } satisfies DashboardViewState;

    appDispatch(updateDashboard(dashboardState));
  }, [appDispatch, dashboardId, state.filters, state.order]);

  return (
    <DashboardContext.Provider
      value={{
        state,
        dispatch,
        queryResult,
        onRecordSelect: handleRecordSelect,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
